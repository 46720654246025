import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'src/components/layouts/default';
import BlockEligibilityForm from 'src/components/blocks/blockEligibilityChecker.js';
import StructuredText from 'src/components/base/structuredText';
import * as classes from './eligibility.module.scss';

export const query = graphql`
  query {
    datoCmsStart {
      eligibilityTitle
      eligibilityLede {
        blocks
        links
        value
      }
    }
  }
`;

const EligibilityPage = ({ data, location }) => {
  return (
    <Layout location={location} pageTitle={data.datoCmsStart.eligibilityTitle}>
      <div className={classes.body}>
        <StructuredText data={data.datoCmsStart.eligibilityLede} />
        <div>
          <BlockEligibilityForm />
        </div>
      </div>
    </Layout>
  );
};

export default EligibilityPage;
