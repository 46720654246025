// https://www.gatsbyjs.org/docs/gatsby-link/#reminder-use-link-only-for-internal-links
import React, { useContext, createContext } from 'react';
import RehypeReact from 'rehype-react';
import classnames from 'classnames';
import AppLink from './appLink';
import * as classes from './markdown.module.scss';

const TokenContext = createContext();

/**
 * Allow inserting a template var like <param name="phone" /> in Dato markdown. We use a context
 * to pass the values in, since there's no other obvious way to pass the values through the AST.
 */
const Token = ({ name }) => {
  const values = useContext(TokenContext);
  return <span>{values[name]}</span>;
};

/**
 * Use a custom renderer to replace anchor tags with our custom AppLink, which deals with
 * local URLs and special cases like #call and #chat.
 */
const AstRenderer = new RehypeReact({
  createElement: React.createElement,
  components: {
    a: ({ href, children }) => <AppLink to={href}>{children}</AppLink>,
    token: Token,
  },
}).Compiler;

const Markdown = ({ node, tokenValues = {}, className, ...others }) => (
  <TokenContext.Provider value={tokenValues}>
    <div {...others} className={classnames(classes.root, className)}>
      {AstRenderer(node.childMarkdownRemark.htmlAst)}
    </div>
  </TokenContext.Provider>
);

export default Markdown;
