import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import keyBy from 'lodash/keyBy';
import last from 'lodash/last';
import pick from 'lodash/pick';
import findLast from 'lodash/findLast';
import { Typeahead } from 'react-bootstrap-typeahead';
import AppLink from 'src/components/base/appLink';
import FormControl from 'src/components/base/formControl';
import Markdown from 'src/components/base/markdown';
import { useGlobalState } from 'src/components/base/globalState';
import * as classes from './blockEligibilityChecker.module.scss';
import { pushToDataLayer } from 'src/ga';

const WAITLIST_FORM_URL = 'https://forms.gle/oxeRhBPpWEHC8d7s7';

const PAYMENT_OPTIONS = [
  {
    label: 'I’ll pay with insurance',
    key: 'insurance',
  },
  {
    label: 'I’ll pay out of pocket (self pay)',
    key: 'self-pay',
  },
  {
    label: 'I’m not sure',
    key: 'unsure',
  },
];

const REFERRAL_CODE_M110 = 'M110Enrollment';

/**
 * Hardcoded Eligibility Response IDs from Dato for specific situations
 */
const PAYMENT_RESPONSES = {
  SELFPAY_WAITLIST: '10368968',
  SELFPAY_ENROLL: '37797003',
  UNSURE: '48919297', // Patient selects unsure from the payment type dropdown
  UNKNOWN: '49854091', // Patient selects other/unsure from the payor dropdown
};

// Special payor appended to selectedPayor to indicate the user isn't sure which child is right so we should use
// use the parent for any eligibility determination.
const OTHER_PAYOR = { name: 'Other/Not Sure' };

/**
 * Does the given payor apply to this state given the rules set in Dato?
 */
function isValidInState(payorsById, payor, state) {
  // A payor is invalid if it's parent is invalid
  if (payor.treeParent && !isValidInState(payorsById, payorsById[payor.treeParent.id], state)) {
    return false;
  }
  // If there's no eligibility rules specified, assume it's national
  if (payor.states.length === 0) {
    return true;
  }
  // Apply whitelist or blacklist
  if (payor.invertStates) {
    return !payor.states.some((aState) => aState.name === state);
  } else {
    return payor.states.some((aState) => aState.name === state);
  }
}

/**
 * Is payor in the subtree of potentialAncestor?
 */
function isAncestor(payorsById, payor, potentialAncestor) {
  return (
    payor &&
    (payor.treeParent?.id === potentialAncestor.id ||
      isAncestor(payorsById, payorsById[payor.treeParent?.id], potentialAncestor))
  );
}

/*
Eligibility respones overrides are currently unused!

function getEligibilityResponseForPayor(payor, state) {
  if (payor?.eligibilityReponseOverrides) {
    const override = find(payor.eligibilityResponseOverrides, override => override.state.name === state);
    if (override) {
      return override.eligibilityResponse;
    }
  }
  return payor?.eligibilityResponse;
}
*/

/**
 * Get the final node that was selected from our tree of payors, which is used for displaying an eligibility outcome.
 * This could be a leaf or an internal node.
 */
function getSelectedPayor(selectedPayors) {
  if (selectedPayors.length === 0) {
    return null;
  }
  // If the last element is null, it means the user is still in the process of navigating the tree to select a child.
  if (last(selectedPayors) === null) {
    return null;
  }
  // Return the last node which isn't "Other/Not Sure".
  return findLast(selectedPayors, (p) => p && p !== OTHER_PAYOR);
}

/**
 * Get the final response given the user input.
 */
function getResponseId(paymentType, state, selectedPayor, statesByName, selectedRootUnknown) {
  switch (paymentType) {
    case 'self-pay':
      return statesByName[state].acceptingSelfPay
        ? PAYMENT_RESPONSES.SELFPAY_ENROLL
        : PAYMENT_RESPONSES.SELFPAY_WAITLIST;
    case 'unsure':
      return PAYMENT_RESPONSES.UNSURE;
    case 'insurance':
      if (selectedPayor) {
        return selectedPayor.eligibilityResponse?.originalId || PAYMENT_RESPONSES.UNKNOWN;
      }
      // If the user selected OTHER_PAYOR as their first response, show the unknown response
      else if (selectedRootUnknown) {
        return PAYMENT_RESPONSES.UNKNOWN;
      }
      // Otherwise they haven't finished selecting, so there's nothing to show
      else {
        return null;
      }
    default:
      return null;
  }
}

function getResponseContent(response) {
  if (response?.ctaTitle && response?.ctaUrl) {
    return { linkUrl: response.ctaUrl, linkLabel: response.ctaTitle };
  }

  const responsePrefix = response?.slug?.split('-')?.[0];
  switch (responsePrefix) {
    case 'accepted':
    case 'm110':
    case 'referral':
      return {
        linkUrl: '/enroll',
        linkLabel: 'Enroll',
      };
    case 'waitlist':
      return {
        linkUrl: WAITLIST_FORM_URL,
        linkLabel: 'Join Waitlist',
      };
    default:
      return {};
  }
}

/**
 * Does the payor have any child payors that are valid in this state, or is it a leaf node?
 */
function hasChildrenInState(payor, payorsById, state) {
  return payor?.treeChildren?.some((child) =>
    isValidInState(payorsById, payorsById[child.id], state),
  );
}

// Displays the content for an eligibility response pulled from datoCms
const EligibilityResponseContent = ({ dataLayerContext, linkUrl, linkLabel, response }) => (
  <div className={classes.response}>
    <Markdown node={response.bodyNode} />
    {linkUrl && (
      <div className={classes.enrollCta}>
        <AppLink
          to={linkUrl}
          className="button"
          onClick={() => {
            pushToDataLayer({
              ...(dataLayerContext || {}),
              event: 'enroll_button',
            });
          }}
        >
          {linkLabel}
        </AppLink>
      </div>
    )}
    <Markdown node={response.postCtaBodyNode} />
  </div>
);

const BlockEligibilityChecker = () => {
  const {
    allDatoCmsEligibilityState: { nodes: states },
    allDatoCmsPayor: { nodes: payors },
    allDatoCmsEligibilityResponse: { nodes: responses },
    allDatoCmsReferralCode: { nodes: referralCodes },
  } = useStaticQuery(graphql`
    query {
      allDatoCmsEligibilityState(sort: { fields: name }) {
        nodes {
          name
          acceptingSelfPay
        }
      }
      allDatoCmsPayor(sort: { fields: name }) {
        nodes {
          id
          originalId
          treeChildren {
            id
          }
          treeParent {
            id
          }
          name
          states {
            name
          }
          invertStates
          eligibilityResponse {
            originalId
          }
          eligibilityResponseOverrides {
            states {
              name
            }
            eligibilityResponse {
              id
            }
          }
          hideFromEligibilityChecker
        }
      }
      allDatoCmsEligibilityResponse {
        nodes {
          id
          originalId
          name
          bodyNode {
            childMarkdownRemark {
              htmlAst
            }
          }
          slug
          postCtaBodyNode {
            childMarkdownRemark {
              htmlAst
            }
          }
          ctaTitle
          ctaUrl
        }
      }
      allDatoCmsReferralCode {
        nodes {
          id
          response {
            slug
          }
          code
          referrerSelection
        }
      }
    }
  `);

  const payorsById = keyBy(payors, 'id');
  const responsesById = keyBy(responses, 'originalId');
  const statesByName = keyBy(states, 'name');

  const {
    state,
    setState,
    paymentType,
    setPaymentType,
    setPayor,
    setPatientReferrer,
    referralCode,
    setReferralCode,
  } = useGlobalState();

  // We store the user selections as an array of increasingly specific payors (ie parent => child => grandchild).
  // The last item can be a special OTHER_PAYOR to indicate the user isn't sure which child is right, or null
  // if the user hasn't selected a child yet.
  const [selectedPayors, setSelectedPayors] = useState([null]);

  // Subset of payors that are valid in the current state
  const [filteredPayors, setFilteredPayors] = useState([]);

  // If a user has entered a valid Referral Code, they should be automatically eligible to enroll
  const [isReferralCodeValid, setIsReferralCodeValid] = useState(false);
  const [referralCodeResponse, setReferralCodeResponse] = useState({}); // response is based on a valid referral code

  // user input concerning M110 will be set here to determine eligibility
  const [isEligibleForM110, setIsEligibleForM110] = useState(false);

  function selectPayor(selected, index) {
    // Truncate selectedPayors to keep the parent and replace the currently selected item.
    const newSelectedPayors = selectedPayors.slice(0, index);
    if (selected) {
      newSelectedPayors.push(selected);
    }
    // If we haven't reached a leaf yet, add a new empty typeahead to the list.
    if (
      newSelectedPayors.length === 0 ||
      hasChildrenInState(last(newSelectedPayors), payorsById, state)
    ) {
      newSelectedPayors.push(null);
    }
    setSelectedPayors(newSelectedPayors);
  }

  function selectState(newState) {
    setState(newState);
    setPaymentType(null);
    selectPayor(null, 0);
    setFilteredPayors(
      payors.filter(
        (payor) => !payor.hideFromEligibilityChecker && isValidInState(payorsById, payor, newState),
      ),
    );
  }

  function selectPaymentType(newPaymentType) {
    setPaymentType(newPaymentType);
    selectPayor(null, 0);
  }

  const hasSelectedPayor = selectedPayors[selectedPayors.length - 1] !== null;

  const selectedPayor = getSelectedPayor(selectedPayors);
  const eligibilityResponse =
    responsesById[
      getResponseId(
        paymentType,
        state,
        selectedPayor,
        statesByName,
        selectedPayors[0] === OTHER_PAYOR,
      )
    ];

  // Update the current payor in global state every time the local payor changes. This allows
  // us to access the selected payor in the enrollment form.
  useEffect(() => {
    setPayor(selectedPayor);
  }, [selectedPayor]);

  // Referral Code logic

  const validateReferralCode = (text, hideField) => {
    setReferralCode(text);
    if (!referralCodes) {
      return null;
    }
    const validReferrer = referralCodes?.find(
      (r) => r.code.toLowerCase() === text.trim().toLowerCase(),
    );

    if (validReferrer) {
      setIsReferralCodeValid(true);
      setPatientReferrer(validReferrer);
      setReferralCodeResponse(responses.find((r) => r.slug === validReferrer.response?.slug));
    } else {
      setIsReferralCodeValid(false);
      setPatientReferrer(null);
      setReferralCodeResponse(null);
    }
  };

  const response =
    isReferralCodeValid && referralCodeResponse ? referralCodeResponse : eligibilityResponse;
  const { linkLabel, linkUrl } = getResponseContent(response);

  const handleM110Eligibility = (selected) => {
    validateReferralCode(selected === 'yes' ? REFERRAL_CODE_M110 : '', selected === 'yes');
    setIsEligibleForM110(selected);
  };

  const showM110Override = () => {
    const enableM110ReferralCode = referralCodes.some((r) => r.code === REFERRAL_CODE_M110);
    const showOnSelectedPaymentType = paymentType && paymentType !== 'insurance';
    const showOnSelectingPayor = paymentType === 'insurance' && hasSelectedPayor;
    return (
      (showOnSelectingPayor || showOnSelectedPaymentType) &&
      state === 'Oregon' &&
      enableM110ReferralCode
    );
  };

  const hideReferralCodeField = isEligibleForM110;

  return (
    <form
      id="eligibility"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <FormControl
        name="state"
        label="Primary state of residence"
        options={states}
        valueFn={(s) => s.name}
        labelFn={(s) => s.name}
        value={state}
        setValue={selectState}
        disabled={isReferralCodeValid}
        required
        autoFocus
      />
      {state && (
        <FormControl
          name="paymentType"
          label="How do you plan to pay for treatment?"
          options={PAYMENT_OPTIONS}
          valueFn={(p) => p.key}
          labelFn={(p) => p.label}
          value={paymentType}
          setValue={selectPaymentType}
          disabled={isReferralCodeValid}
          required
          autoFocus
        />
      )}
      {paymentType === 'insurance' &&
        selectedPayors.map((payor, i) => {
          // Use the previous payor as a key so that changing the parent will create a new child and autofocus it.
          const key = `payor${i > 0 ? selectedPayors[i - 1]?.id : ''}`;

          // Use all options for the first typeahead, and just the descendent options for any subsequent.
          const options =
            i === 0
              ? filteredPayors
              : filteredPayors.filter((aPayor) =>
                  isAncestor(payorsById, aPayor, selectedPayors[i - 1]),
                );

          return (
            <FormControl key={key}>
              <Typeahead
                id={key}
                labelKey={(aPayor) =>
                  hasChildrenInState(aPayor, payorsById, state) ? `${aPayor.name}...` : aPayor.name
                }
                options={[...options, OTHER_PAYOR]}
                selected={payor ? [payor] : []}
                onChange={(selected) => selectPayor(selected[0], i)}
                filterBy={(option, props) => {
                  return (
                    option === OTHER_PAYOR ||
                    option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
                  );
                }}
                placeholder={i === 0 ? 'Search for your insurance provider...' : 'Which?'}
                selectHintOnEnter
                disabled={isReferralCodeValid}
                autoFocus
                className={classes.smallText}
              />
            </FormControl>
          );
        })}

      {/* Hide Insurance before showing M110Override */}
      {showM110Override() && (
        <div>
          <div style={{ margin: '1em 0' }}>
            Are you in Columbia, Deschutes, Douglas, Gilliam, Grant, Sherman, Wallowa, or Wheeler
            county?
          </div>
          <FormControl
            name="isEligibleForM110"
            label="Choose an option"
            options={[
              {
                label: 'Yes',
                key: 'yes',
              },
              {
                label: 'No',
                key: 'no',
              },
            ]}
            valueFn={(p) => p.key}
            labelFn={(p) => p.label}
            value={isEligibleForM110}
            setValue={handleM110Eligibility}
            required
          />
        </div>
      )}

      {!hideReferralCodeField && (
        <FormControl
          name="referralCode"
          label="Referral code (optional)"
          value={referralCode}
          setValue={validateReferralCode}
          required
        />
      )}

      {response && (
        <EligibilityResponseContent
          dataLayerContext={{
            paymentType,
            response: response.slug,
            payor: selectedPayor ? pick(selectedPayor, ['id', 'name']) : null,
            state,
          }}
          linkUrl={linkUrl}
          linkLabel={linkLabel}
          response={response}
        />
      )}
    </form>
  );
};

export default BlockEligibilityChecker;
